export enum SupportedRoles {
  CT = 'CT',
  BOK = 'BOK',
  PH_INTERNAL = 'PH_INTERNAL',
  PH_EXTERNAL = 'PH_EXTERNAL',
  PZ = 'PZ',
  DWS = 'DWS',
  MARKETING = 'MARKETING',
  BILLING = 'BILLING',
  CONTROLLING = 'CONTROLLING',
  ADMIN = 'ADMIN',
  SALES_MANAGER = 'SALES_MANAGER',
  SP = 'SP',
  BIZ_ADM = 'BIZ_ADM',
  EXISTING_CUSTOMER = 'EXISTING CUSTOMER',
  NEW_CUSTOMER = 'NEW CUSTOMER',
  MANAGER = 'MANAGER',
  ALL_CHANNELS = 'ALL_CHANNELS',
  CT_EOP_3 = 'CT_EOP_3',
  CT_EOP_6 = 'CT_EOP_6',
  CT_LIVE_CHAT = 'CT_LIVE_CHAT',
  CT_MAIL = 'CT_MAIL',
  CT_PRZYCH = 'CT_PRZYCH',
  eSales = 'eSales',
  EOP = 'EOP',
  WINBACK = 'WINBACK',
  CT_PZ = 'CT_PZ',
  CT_WYCH = 'CT_WYCH',
  CM = 'CM',
  BOKSF = 'BOKSF',
  BOKSFRetention = 'BOKSFRetention',
  COK = 'COK',
  'CT Retention' = 'CT Retention',
  PH_CT = 'PH_CT',
  PARTNER_ZEW = 'PARTNER_ZEW',
  CT_PH = 'CT_PH',
  CT_ESALES = 'CT_ESALES',
  CT_BOK = 'CT_BOK',
  CT_CUK = 'CT_CUK',
  CT_COK = 'CT_COK',
  BOK_PH = 'BOK_PH',
  BOK_ESALES = 'BOK_ESALES',
  BOK_CT = 'BOK_CT',
  BOK_COK = 'BOK_COK',
  BOK_CUK = 'BOK_CUK',
  CUK_PH = 'CUK_PH',
  CUK_ESALES = 'CUK_ESALES',
  CUK_CT = 'CUK_CT',
  CUK_BOK = 'CUK_BOK',
  CUK_COK = 'CUK_COK',
  VIEW_360 = 'VIEW_360',
  POS = 'POS',
  DOK = 'DOK',
  DUK_CHURN = 'DUK_CHURN',
  DUK_EOP = 'DUK_EOP',
  DUK_EOP1 = 'DUK_EOP1',
  HUSAR = 'HUSAR'
}
