declare const require: any;
const npm = require('../../package.json');

const apiEnvs = {
  auth: window['env'] ? window['env']['authAdapterUrl'] : null,
  offersPortal: window['env'] ? window['env']['offersPortalUrl'] : null,
  captchaSiteKey: window['env'] ? window['env']['captchaSiteKey'] : null
};

export const environment = {
  production: true,

  // OFFERS-PORTAL ENDPOINTS
  OFFER_PORTAL_API_URL: apiEnvs.offersPortal,
  FILES_API_URL: `${apiEnvs.offersPortal}/order-files`,
  EXPORT_API_URL: `${apiEnvs.offersPortal}/exports`,
  EVENTS_API_URL: `${apiEnvs.offersPortal}/events`,
  CUSTOMER_SEGMENT_API_URL: `${apiEnvs.offersPortal}/customer-segments`,
  AUTH_API_URL_CHANGE_PASSWORD: `${apiEnvs.auth}/change-password-by-username`,

  // AUTH ENDPOINTS
  AUTH_API_URL: `${apiEnvs.auth}/token`,
  AUTH_LOGOUT_URL: `${apiEnvs.auth}/token/logout`,
  AUTH_INFO_API_URL: `${apiEnvs.auth}/token/authinfo`,
  AUTH_USERS_API_URL: `${apiEnvs.auth}/users`,
  USERS_HIERARCHY_API_URL: `${apiEnvs.auth}/users/hierarchy`,
  AUTH_USERS_GROUP_API_URL: `${apiEnvs.auth}/user-groups`,
  DATA_LIMIT: `${apiEnvs.auth}/security`,

  USER_IP: 'https://ipinfo.io/json',
  version: npm.version,

  CAPTCHA_SITE_KEY: apiEnvs.captchaSiteKey
};
